import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InvoiceItem from './InvoiceItem';
import InvoiceModal from './InvoiceModal';
import InputGroup from 'react-bootstrap/InputGroup';
import numberToWords from 'number-to-words';

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currency: '$',
      currentDate: '',
      invoiceNumber: 1,
      dateOfIssue: '',
      billTo: '',
      billToEmail: '',
      billToAddress: '',
      billFrom: '',
      billFromEmail: '',
      billFromAddress: '',
      notes: '',
      total: '0.00',
      subTotal: '0.00',
      taxRate: '',
      taxAmmount: '0.00',
      discountRate: '',
      igstRate: '',
      igstAmmount: '0.00',
      discountAmmount: '0.00',
      items: Array.from({ length: 1 }, (_, index) => ({
        id: index,
        name: '',
        hsncode: '',
        price: '0.00',
        description: '',
        quantity: 0,
        editable: index>2, // Set editable to false for the initial items
      })),
    };
    this.editField = this.editField.bind(this);
  }
  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  convertTotalToWords(total) {
    // Parse total as a float
    const numericPart = parseFloat(total);
  
    // Check if numericPart is a valid finite number
    if (!Number.isFinite(numericPart) || Number.isNaN(numericPart)) {
      return 'Invalid Amount';
    }
  
    // Separate the total into integer and decimal parts
    const integerPart = Math.floor(numericPart);
    const decimalPart = Math.round((numericPart - integerPart) * 100);
  
    // Convert integer and decimal parts to words
    const integerWords = numberToWords.toWords(integerPart);
    const decimalWords = numberToWords.toWords(decimalPart);
  
    // Build the final representation
    const rupeesInWords = `${integerWords} Rupees only`;
    const paiseInWords = decimalWords !== 'zero' ? `${decimalWords} paise only` : '';
  
    // Combine the rupees and paise parts
    const result = rupeesInWords + (paiseInWords ? ` and ${paiseInWords}` : '');
  
    return result || 'Invalid Amount';
  }

  componentDidMount(prevProps) {
    this.handleCalculateTotal()
  }
  handleRowDel(items) {
    var index = this.state.items.indexOf(items);
    this.state.items.splice(index, 1);
    this.setState(this.state.items);
  };
  handleAddEvent(evt) {
    var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: '',
      hsncode: '',
      price: '1.00',
      description: '',
      quantity: '0'
    }
    this.state.items.push(items);
    this.setState(this.state.items);
  }

handleCalculateTotal() {
  var items = this.state.items;
  var subTotal = 0;

  // Calculate subTotal for each item
  const updatedItems = items.map(item => {
    const quantity = parseFloat(item.quantity) || 0; // Ensure quantity is a valid number or default to 0
    const price = parseFloat(item.price) || 0; // Ensure price is a valid number or default to 0
    const itemTotal = quantity * price;
    subTotal += itemTotal;

    return {
      ...item,
      subTotal: itemTotal.toFixed(2),
    };
  });

  this.setState({
    items: updatedItems,
    subTotal: subTotal.toFixed(2),
  }, () => {
    // Now you can calculate other totals based on the updated subtotals

    // Calculate tax amount
    const taxAmmount = parseFloat(subTotal * (this.state.taxRate / 100)).toFixed(2);

    // Calculate discount amount
    const discountAmmount = parseFloat(subTotal * (this.state.discountRate / 100)).toFixed(2);

    // Calculate igst amount
    const igstAmmount = parseFloat(subTotal * (this.state.igstRate / 100)).toFixed(2);

    // Calculate total amount
    const total = (
      parseFloat(subTotal) +
      parseFloat(taxAmmount) +
      parseFloat(discountAmmount) +
      parseFloat(igstAmmount)
    ).toFixed(2);

    this.setState({
      taxAmmount,
      discountAmmount,
      igstAmmount,
      total,
    });
  });

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  }
}
  onItemizedItemEdit(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value
    };
    var items = this.state.items.slice();
    var newItems = items.map(function(items) {
      for (var key in items) {
        if (key == item.name && items.id == item.id) {
          items[key] = item.value;
        }
      }
      return items;
    });
    this.setState({items: newItems});
    this.handleCalculateTotal();
  };
  editField = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.handleCalculateTotal();
  };
  onCurrencyChange = (selectedOption) => {
    this.setState(selectedOption);
  };
  openModal = (event) => {
    event.preventDefault()
    this.handleCalculateTotal()
    this.setState({isOpen: true})
  };
  closeModal = (event) => this.setState({isOpen: false});
  render() {
    return (<Form onSubmit={this.openModal}>
      <Row>
        <Col md={8} lg={9}>
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div class="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div class="mb-2">

                    
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4"/>
            <Row className="mb-5">
              <Col md={8}>
                <Form.Label className="fw-bold">Bill To</Form.Label>
                <Form.Control placeholder={"Name"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
                <Form.Control placeholder={"Party GSINT"} value={this.state.billToEmail} type="text" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="text" required="required"/>
              </Col>
              <Col md={4}>
                <Form.Label className="fw-bold"></Form.Label>
                <div className="d-flex flex-row align-items-center">
                <span className="fw-bold me-2">Invoice&nbsp;Number:&nbsp;</span><br></br>
                <Form.Control type="number" value={this.state.invoiceNumber} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                    maxWidth: '90px'
                  }} required="required"/>
              </div>
              <br></br>
              <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">&nbsp;Date:</span>
                  <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                      maxWidth: '150px'
                    }} required="required"/>
                </div>
              </Col>
            </Row>
            <InvoiceItem onItemizedItemEdit={this.onItemizedItemEdit.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} currency={this.state.currency} items={this.state.items}/>
            <Row className="mt-4 justify-content-end">
              <Col lg={6}>
                <div className="d-flex flex-row align-items-start justify-content-between">
                  <span className="fw-bold">Subtotal:
                  </span>
                  <span>₹
                    {this.state.subTotal}</span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">SGST:
                  </span>
                  <span>
                    <span className="small ">({this.state.taxRate || 0}%)</span>
                    ₹
                    {this.state.taxAmmount || 0}</span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">CGST:</span>
                  <span>
                    <span className="small ">({this.state.discountRate || 0}%)</span>
                    ₹
                    {this.state.discountAmmount || 0}</span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">IGST:
                  </span>
                  <span>
                    <span className="small ">({this.state.igstRate || 0}%)</span>
                    ₹
                    {this.state.igstAmmount || 0}</span>
                </div>
                <hr/>
                
                <div className="d-flex flex-row align-items-start justify-content-between" style={{
                    fontSize: '1.125rem'
                  }}>
                  <span className="fw-bold">Total:
                  </span>
                  <span className="fw-bold">₹ {this.formatNumberWithCommas(this.state.total || 0)}</span>
                </div>
              </Col>
            </Row>
            <hr className="my-2"/>
            <Form.Label className="fw-bold">E-Way Bill No.</Form.Label>
            <Form.Control placeholder="Write E-Way Bill Number" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} type="text" className="my-2" rows={1} style={{
                    maxWidth: '20%'}}/>
            <hr className="my-2"/>
            <div className="mb-1 fw-bold"><u>BANK DETAILS:</u></div>
            <h10>Name  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: SUDHA PUPILS</h10>
            <h10>Bank Name &nbsp;&nbsp;: INDIAN BANK</h10>
            <h10>A/C No  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 7255950803</h10>
            <h10>IFSC No  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: IDIB000P021</h10>
            <h10>Branch  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: PARAMATHI</h10>

            <hr className="my-2"/>

            <div className="d-flex flex-row align-items-start" style={{ fontSize: '1rem' }}>
              <span className="fw-bold">Amount Chargeable in words&nbsp;:&nbsp;</span>
              <span>
              {this.convertTotalToWords(this.state.total || 0)}
              </span>
            </div>
            
            <hr className="my-2"/>
            
            <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">Vehicle Number&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Vehicle Number"} value={this.state.vehicle} type="text" name="vehicle" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} rows={1} style={{
                    maxWidth: '20%'}}/>
                  </div>
                  <div className="position-relative mt-4">
          <Form.Group className="mb-5 position-absolute bottom-0 end-0 me-2 ml-5">
            <Form.Label className="fw-bold">For SUDHA PUPILS</Form.Label>
          </Form.Group>
        </div>
            <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">Name&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Driver Name"} value={this.state.drivername} type="text" name="drivername" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required" rows={1} style={{
                    maxWidth: '20%'}}/><p> </p>&nbsp;&nbsp;&nbsp;
                    <span className="fw-bold me-2">Mobile&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Driver Phone Number"} value={this.state.drivernumber} type="number" name="drivernumber" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required" rows={1} style={{
                    maxWidth: '20%'}}/>
            </div> 
            {/* Signature section */}
        <div className="position-relative mt-4">
          <Form.Group className="mb-3 position-absolute bottom-0 end-0">
            <Form.Label>Authorised Signatory</Form.Label>
          </Form.Group>
        </div>
                                
          </Card>
        </Col>
        <Col md={4} lg={3}>
          <div className="sticky-top pt-md-3 pt-xl-4">
            <Button variant="primary" type="submit" className="d-block w-100 btn-secondary">Review Invoice</Button>
            <InvoiceModal showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmmount={this.state.taxAmmount} discountAmmount={this.state.discountAmmount} igstAmmount={this.state.igstAmmount} total={this.state.total}/>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select onChange={event => this.onCurrencyChange({currency: event.target.value})} className="btn btn-light my-1" aria-label="Change Currency">
                <option value="₹">INR (Indian Rupee)</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="fw-bold">ADD SGST:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control name="taxRate" type="number" value={this.state.taxRate} onChange={(event) => this.editField(event)} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00"/>
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="fw-bold">ADD CGST:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control name="discountRate" type="number" value={this.state.discountRate} onChange={(event) => this.editField(event)} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00"/>
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="fw-bold">ADD IGST:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control name="igstRate" type="number" value={this.state.igstRate} onChange={(event) => this.editField(event)} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00"/>
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Form>)
  }
}

export default InvoiceForm;
