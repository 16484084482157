import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import numberToWords from 'number-to-words';


function GenerateInvoice() {
  const scale = window.devicePixelRatio || 1;
  const dpi = 300;
  html2canvas(document.querySelector("#invoiceCapture"), { scale: scale }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png', 1.0);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      dpi: dpi
    });
    pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
    pdf.save('invoice-001.pdf');
  });
}

class InvoiceModal extends React.Component {
  constructor(props) {
    super(props);
  }
  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  convertTotalToWords(total) {
    const numericPart = parseFloat(total);
    const integerPart = Math.floor(numericPart);
    const decimalPart = Math.round((numericPart - integerPart) * 100);

    const integerWords = numberToWords.toWords(integerPart);
    const decimalWords = numberToWords.toWords(decimalPart);

    const rupeesInWords = `${integerWords} Rupees only`;
    const paiseInWords = `${decimalWords} paise only`;

    return `${rupeesInWords} and ${paiseInWords}`;
  }

  render() {
    return(
      <div>
        <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg" centered>
          <div id="invoiceCapture">
            <div className="d-flex text-align center justify-content-between align-items-center bg-light w-100 p-2">
            <p className='position-absolute top-0 start-2 mb-0 me-0'><b>GSTIN&nbsp;:&nbsp;3AYPPT1409F1ZE</b></p>
              <div className=" w-100 text-center">
              <h6 className="fw-bold">{this.props.info.billFrom||'TAX INVOICE'}</h6>
                <h4 className="fw-bold">{this.props.info.billFrom||'SUDHA PUPILS'}</h4>
                <h8 className="fw-bold">{this.props.info.billFrom||'5/344, SURAKKAPALAYAN, RASAMPALAYAM,'}</h8><br></br>
                <h8 className="fw-bold">{this.props.info.billFrom||'Namakkal, Tamil Nadu, 637207'}</h8><br></br>
                <h8 className="fw-bold">{this.props.info.billFrom||'Mail Id : sgsk.kannan@gmail.com'}</h8>
              </div>
              <div className='position-absolute top-0 end-0 mb-3 me-3'>
                <h8 className="fw-bold">Cell&nbsp;:&nbsp;{this.props.info.billFrom||'9443331768'}</h8><br></br>
                <h8 className="fw-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.info.billFrom||'9943901550'}</h8><br></br>
              </div>
            </div>
            
            <div className="p-4">
              <Row className="mb-4">
                <Col md={9}>
                  <div className="fw-bold"></div>
                  <div><b>Bill To : </b>{this.props.info.billTo||''}</div>
                  <div>{this.props.info.billToAddress||''}</div>
                  <div><b>Party GSINT : </b>{this.props.info.billToEmail||''}</div>
                </Col>
                <Col md={3}>
                  <div className="fw-bold mt-2"></div>
                  <div><b>Invoice: </b>{this.props.info.invoiceNumber||''}</div><br></br>
                  <div className="fw-bold mt-2"></div>
                  <div><b>Date: </b>{this.props.info.dateOfIssue||''}</div>
                </Col>
              </Row>
              <div style={{position:'relative',height:'320px'}}>
              <Table>
                <thead>
                  <tr style={{backgroundColor: '#ADD8E6'}}>
                    <th>DESCRIPTION</th>
                    <th className="text-end">HSN CODE</th>
                    <th className="text-end">QTY</th>
                    <th className="text-end">PRICE</th>
                    <th className="text-end">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map((item, i) => {
                    return (
                      <tr id={i} key={i}>
                        <td style={{width: '100px'}}>{item.name}</td>
                        <td className="text-end">{item.hsncode}</td>
                        <td className="text-end">{item.quantity}</td>
                        <td className="text-end" style={{width: '100px'}}>₹ {item.price}</td>
                        <td className="text-end" style={{width: '100px'}}>₹ {item.price * item.quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              </div>
              <Table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>SUBTOTAL</td>
                    <td className="text-end" style={{ width: '100px' }}>
                ₹ {this.formatNumberWithCommas(this.props.subTotal)}
              </td>
                  </tr>
                  {this.props.taxAmmount != 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>SGST</td>
                      <td className="text-end" style={{width: '100px'}}>₹ {this.formatNumberWithCommas(this.props.taxAmmount)}</td>
                    </tr>
                  }
                  {this.props.discountAmmount != 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>CGST</td>
                      <td className="text-end" style={{width: '100px'}}>₹{this.formatNumberWithCommas(this.props.discountAmmount)}</td>
                    </tr>
                  }
                  {this.props.igstAmmount != 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>IGST</td>
                      <td className="text-end" style={{width: '100px'}}>₹ {this.formatNumberWithCommas(this.props.igstAmmount)}</td>
                    </tr>
                  }
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>TOTAL</td>
                    <td className="text-end" style={{ width: '100px' }}>
                ₹ {this.formatNumberWithCommas(this.props.total)}
              </td>
                  </tr>
                </tbody>
              </Table >
              {this.props.info.notes &&
                <div className="bg-light px-3 rounded"><p>E-Way Bill Number : {this.props.info.notes}</p> </div>}
                <hr className="my-2"/>
                <div className="mb-1 fw-bold"><u>BANK DETAILS:</u></div>
                  <h10>Name  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: SUDHA PUPILS</h10><br></br>
                  <h10>Bank Name &nbsp;&nbsp;: INDIAN BANK</h10><br></br>
                  <h10>A/C No  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 7255950803</h10><br></br>
                  <h10>IFSC No  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: IDIB000P021</h10><br></br>
                  <h10>Branch  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: PARAMATHI</h10><br></br>
                <hr className="my-2"/>

            <div className="d-flex flex-row align-items-start" style={{ fontSize: '0.9rem' }}>
              <span className="fw-bold">Amount Chargeable in words&nbsp;:&nbsp;</span>
              <span>
              {this.convertTotalToWords(this.props.info.total || 0)}
              </span>
            </div>
            <hr className="my-2"/>
            
            <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">Vehicle Number&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Vehicle Number"} value={this.props.info.vehicle} type="text" name="vehicle" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required" rows={1} style={{
                    maxWidth: '20%'}}/>
                  </div>
                  <div className="position-relative mt-4">
                    <Form.Group className="mb-5 position-absolute bottom-0 end-0 me-2 ml-5">
                      <Form.Label className="fw-bold">For SUDHA PUPILS</Form.Label>
                    </Form.Group>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">Name&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Driver Name"} value={this.props.info.drivername} type="text" name="drivername" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required" rows={1} style={{
                    maxWidth: '20%'}}/><p> </p>&nbsp;&nbsp;&nbsp;
                    <span className="fw-bold me-2">Mobile&nbsp;:&nbsp;</span>
                    <Form.Control placeholder={"Driver Phone Number"} value={this.props.info.drivernumber} type="text" name="drivernumber" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required" rows={1} style={{
                    maxWidth: '20%'}}/>
                  </div>
                  </div>
                  <div className="position-relative mt-4">
                    <Form.Group className="mb-3 position-absolute bottom-0 end-0 me-4">
                      <Form.Label>Authorised Signatory</Form.Label>
                    </Form.Group>
                  </div>
                </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6}>
              </Col>
              <Col md={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={GenerateInvoice}>
                  <BiCloudDownload style={{width: '16px', height: '16px', marginTop: '-3px'}} className="me-2"/>
                  Download Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3"/>
      </div>
    )
  }
}

export default InvoiceModal;
